@import url("https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext");

$primaryColor: #230e3a;
$font-stack: Poppins, sans-serif;

body {
    scroll-behavior: smooth;
    background-color: #f4f4f7;
    font-family: $font-stack;
    font-display: swap;
}

/* ul {
    display: inline-flexbox;
} */

/* img {
    border-radius: 50%;
    width: 100px;
} */

.responsive-wrapper {
    max-width: 1200px;
    margin: auto;
}

.btn {
    background-color: $primaryColor !important;
    border-color: $primaryColor !important;
    padding: 10px 20px !important;
    outline: none;
    outline-style: none;

    &:hover {
        background-color: #391a5a !important;
        transition: 0.5s;
    }
}

.section-title {
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 50px !important;
    margin-bottom: 50px;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.self-portrait-container-m {
    display: none;
}

.navbar-nav-items-icon {
    display: none;
}

@media screen and (max-width: 480px) {
    .contact-field {
        min-width: 50px !important;
    }

    .card {
        margin-bottom: 20px;
    }

    .self-portrait-container {
        display: none;
    }

    .self-portrait-container-m {
        display: block;
    }

    .contact-field-list,
    .contact-method-list {
        padding-left: 0px;
    }

    .navbar-nav-items-container {
        display: none;
    }

    .about-main-container {
        margin-top: 60px !important;
        margin-bottom: 50px !important;
    }

    .navbar {
        min-height: 90px;
    }
}
