.card {
    cursor: pointer;
    &:hover {
        box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
        transition: 0.5s;
    }
    img {
        width: 60px;
    }
}

ul.service-list {
    list-style: none;

    li {
        text-indent: -5px;

        &::before {
            display: inline-block;
            content: "-";
            text-indent: -5px;
            margin-right: 10px;
        }
    }
}
