@import "../styles/app.scss";

$hoverColor: #e5d9f341;

.footer {
    background-color: $primaryColor;
    color: white;

    button {
        color: white;
    }
}

.navbar-content {
    margin: auto !important;
    max-width: 1200px;
    align-items: center;

    ul {
        list-style: none;
    }

    li {
        float: left;
        cursor: pointer;
        margin-right: 20px;
        margin-left: 20px;

        button {
            display: block;
            text-align: center;
            padding: 16px;
            text-decoration: none;
            font-weight: 600;
            background-color: transparent;
            border: none;
        }

        &:hover {
            background-color: $hoverColor;
            border-radius: 10px;
            transition: 0.5s;
            opacity: 1;
        }
    }
}
