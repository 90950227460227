@import "../../styles/app";

$hoverColor: #e5d9f341;

.contact-main-container {
    margin-bottom: 60px;
}

.contact-field {
    margin-bottom: 20px;
    background-color: $hoverColor;
    padding: 15px;
    min-width: 400px;
    border-radius: 8px;
    position: relative;

    div {
        &:nth-child(1) {
            color: black;
        }
    }

    .contact-input {
        width: 100%;
        border: none;
        background-color: transparent;
        color: black;
        outline: none;

        &:focus {
            border: none;
        }
    }

    textarea {
        resize: none;
        min-height: 130px;

        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: $primaryColor;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .contact-field-error-indicator {
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
    }
}

.contact-field-list {
    li {
        list-style: none;
    }

    .btn {
        transition: 0.5s;
    }

    .success-message {
        color: #4daf51;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .error-message {
        color: red;
        font-weight: 600;
        margin-bottom: 20px;
    }
}

.contact-method-list {
    li {
        list-style: none;
        color: grey;
        margin-bottom: 20px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
            color: $primaryColor;
            transition: 0.5s;
        }
    }

    .contact-method-icon {
        width: 25px;
        margin-right: 20px;
        font-size: 1.5rem;
    }

    .contact-method-subtitle {
        font-size: 0.85rem;
    }
}
