@import "../../styles/app.scss";

.hi-text {
    font-weight: 500;
    font-size: 2.5rem;
}

.self-portrait {
    border: 1px solid $primaryColor;
    border-radius: 50%;
}
